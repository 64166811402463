import { Query, SanityPage } from "@graphql-types";
import React from "react";
import { PageProps, graphql } from "gatsby";
import { BreadcrumbPageContext } from "@util/types";
import PageComponents from "@components/pageComponents";
import SEO from "@shared/seo";
import Layout from "@shared/layout";
import { Container } from "@util/standard";
import Hero from "@shared/hero";
import { Breadcrumb, HeroCarousel } from "@global";

interface Props extends PageProps {
  data: Query;
  pageContext: BreadcrumbPageContext;
}

const PageTemplate = ({ data, pageContext }: Props) => {
  if (!data) return null;
  const page = data.allSanityPage.nodes[0] as SanityPage;

  return (
    <>
      {page.seo && <SEO seoData={page.seo} />}
      <Layout>
        <Container flexDirection="column">
          {page?.hero && page?.additionalHeros?.length ? (
            <HeroCarousel
              hero={page.hero}
              additionalHeros={page.additionalHeros}
              homeHero
            />
          ) : (
            <Hero sanityHero={page.hero} height="700px" classname="no-margin" />
          )}
          <Breadcrumb pageContext={pageContext} />
          {page?.components?.map(component => {
            return <PageComponents data={component} />;
          })}
        </Container>
      </Layout>
    </>
  );
};

export default PageTemplate;

export const query = graphql`
  query pageQuery($id: String) {
    allSanityPage(filter: { _id: { eq: $id } }) {
      nodes {
        _id
        seo {
          ...sanitySeo
        }
        hero {
          ...sanityHero
        }
        additionalHeros {
          ...sanityHero
        }
        components {
          ... on SanityFullWidthTextBlock {
            ...sanityFullWidthTextBlock
          }
          ... on SanityGallery {
            ...sanityGallery
          }
          ... on SanityImageGrid {
            ...sanityImageGrid
          }
          ... on SanityTextCta {
            ...sanityTextCta
          }
          ... on SanityImageTextBlock {
            ...sanityImageTextBlock
          }
          ... on SanityImageWithMeta {
            ...sanityImageFullWidth
          }
          ... on SanityHtml {
            _key
            _type
            content
          }
          ... on SanityVideo {
            _key
            _type
            url
          }
          ... on SanityJotform {
            ...sanityJotform
          }
        }
      }
    }
  }
`;
